import React from "react";
import { Button, Icon, Layout, Menu, Dropdown, Avatar } from "antd";
import { signOut } from "../../services/auth";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserFromStore } from "../../helpers/store";

const { Header } = Layout;

const CustomHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0 16px 0 0;
`;

const HeaderTopLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTopRightContainer = styled.div`
  display: flex;
`;

const PageHeader = styled.h2`
  margin: 0;
`;

export default ({ collapsed, handleCollapsed }) => {
  const { pageHeader } = useSelector((state) => state.app);

  return (
    <CustomHeader>
      <HeaderTopLeftContainer>
        <Icon
          className="trigger"
          type={collapsed ? "menu-unfold" : "menu-fold"}
          onClick={handleCollapsed}
        />
        <PageHeader>{pageHeader}</PageHeader>
      </HeaderTopLeftContainer>
      <HeaderTopRightContainer>
        <UserOptions />
      </HeaderTopRightContainer>
    </CustomHeader>
  );
};

const UserOptions = () => {
  const user = getUserFromStore();

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/dashboard/my_profile">Meu Perfil</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <a href="#" onClick={() => signOut()}>
          Sair
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      {user.photoURL ? (
        <Avatar src={user.photoURL} />
      ) : (
        <Button type="primary" shape="round" icon="setting" size="large" />
      )}
    </Dropdown>
  );
};
