import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Layout, PageHeader } from "antd";
import { AppActions } from "../ducks/actions";
import Spin from "./Spin";

const { Content } = Layout;

export default ({
  children,
  extras,
  headerTitle,
  loading,
  pageHeaderTitle,
  pageHeaderSubtitle,
  pageHeaderIcon,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AppActions.setPageHeader(headerTitle));

    return function cleanup() {
      dispatch(AppActions.setPageHeader(""));
    };
  }, [headerTitle]);

  if (loading)
    return (
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </Content>
    );

  return (
    <>
      {(pageHeaderTitle || pageHeaderSubtitle) && (
        <PageHeader
          title={pageHeaderTitle}
          style={{
            background: "#fff",
            border: "1px solid rgb(235, 237, 240)",
          }}
          extra={extras}
          subTitle={pageHeaderSubtitle}
          avatar={
            pageHeaderIcon && {
              icon: pageHeaderIcon,
              style: { backgroundColor: "#001529" },
            }
          }
        />
      )}
      <Content
        style={{
          margin: "24px 16px",
          padding: 24,
          background: "#fff",
          minHeight: "auto",
        }}
      >
        {children}
      </Content>
    </>
  );
};
