import { message } from "antd";

export function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error(
      "Você só pode realizar o upload de imagem do tipo JPG ou PNG"
    );
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Imagem deve ser menor que 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
