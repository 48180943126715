import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import AppWithReduxStore from "./store";
import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://fbf973055aa1444696987e16cf21ff88@sentry.io/2675511",
});

ReactDOM.render(<AppWithReduxStore />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
