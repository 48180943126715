import {
  createColListener,
  getColData,
  getColRef,
  getDoc,
  getDocRef,
} from "../firestore";
import { DECREASE_OPERATION, INCREASE_OPERATION } from "./forumPosts";
import { functions } from "../firebase";

const FORUM_CATEGORIES_PATH = "forum_categories";

export const getForumCategoriesService = async () => {
  return await getColData(FORUM_CATEGORIES_PATH, { orderBy: "name" });
};

export const getSingleForumCategory = async (categoryId) => {
  return await getDoc(`${FORUM_CATEGORIES_PATH}/${categoryId}`);
};

const updateForumCategoryService = async (categoryId, newCategoryData) => {
  const categoryRef = getDocRef(`${FORUM_CATEGORIES_PATH}/${categoryId}`);
  return await categoryRef.update(newCategoryData);
};

export const updateForumCategoryStateQuantity = async (
  stateId,
  categoryId,
  operation
) => {
  const categoryDetails = await getSingleForumCategory(categoryId);

  const stateQuantity =
    (categoryDetails.quantity_per_state &&
      categoryDetails.quantity_per_state[stateId]) ||
    0;

  let newQuantity = 0;
  if (operation === INCREASE_OPERATION) {
    newQuantity = stateQuantity + 1;
  }

  if (operation === DECREASE_OPERATION && stateQuantity > 0) {
    newQuantity = stateQuantity - 1;
  }

  const quantityPerState = {
    ...categoryDetails.quantity_per_state,
    [stateId]: newQuantity,
  };

  return await updateForumCategoryService(categoryDetails.id, {
    quantity_per_state: quantityPerState,
  });
};
export const createForumCategoriesListener = (callback) => {
  createColListener(FORUM_CATEGORIES_PATH, callback, {
    orderBy: "name",
  });
};
export const createNewForumCategory = async (values) => {
  const newsRef = getColRef(FORUM_CATEGORIES_PATH, {});
  return await newsRef.add(values);
};
export const updateForumCategoryCategoryService = async (category, value) => {
  const funcUpdate = functions.httpsCallable("updateForumCategory");
  const result = await funcUpdate({ category, newData: value });
  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};
export const deleteForumCategoryService = async (category) => {
  const funcDelete = functions.httpsCallable("deleteForumCategory");
  const result = await funcDelete({ category });
  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};
