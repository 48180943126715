import React from "react";
import Loadable from "react-loadable";
import Spin from "../components/Spin";

const loading = () => <Spin />;

export const UserSignInScreen = Loadable({
  loader: () => import("../screens/auth/userLogin"),
  loading,
});
export const UserSignUpScreen = Loadable({
  loader: () => import("../screens/auth/userRegister"),
  loading,
});

export const SignInScreen = Loadable({
  loader: () => import("../screens/auth/login"),
  loading,
});

export const HomeScreen = Loadable({
  loader: () => import("../screens/app/HomeScreen"),
  loading,
});

export const NewsScreen = Loadable({
  loader: () => import("../screens/news/NewsScreen"),
  loading,
});

export const NewsScreenFeed = Loadable({
  loader: () => import("../screens/news/NewsScreenFeed"),
  loading,
});

export const InterestsAreaScreen = Loadable({
  loader: () => import("../screens/news/InterestsAreaScreen"),
  loading,
});

export const ProfileScreen = Loadable({
  loader: () => import("../screens/app/ProfileScreen"),
  loading,
});
export const UserProfileScreen = Loadable({
  loader: () => import("../screens/app/UserProfileScreen"),
  loading,
});
export const UserInterestScreen = Loadable({
  loader: () => import("../screens/app/UserInterestScreen"),
  loading,
});

export const UsersScreen = Loadable({
  loader: () => import("../screens/users/UsersScreen"),
  loading,
});

export const SegmentsScreen = Loadable({
  loader: () => import("../screens/segments/SegmentsScreen"),
  loading,
});

export const AffiliateScreen = Loadable({
  loader: () => import("../screens/users/AffiliateScreen"),
  loading,
});

export const EventsScreen = Loadable({
  loader: () => import("../screens/events/EventsScreen"),
  loading,
});

export const EventsScreenFeed = Loadable({
  loader: () => import("../screens/events/EventsScreenFeed"),
  loading,
});

export const DiscountsScreen = Loadable({
  loader: () => import("../screens/discount_club/DiscountsScreen"),
  loading,
});

export const DiscountsScreenFeed = Loadable({
  loader: () => import("../screens/discount_club/DiscountsScreenFeed"),
  loading,
});

export const DiscountClubCategories = Loadable({
  loader: () => import("../screens/discount_club/CategoriesScreen"),
  loading,
});

export const ForumCategories = Loadable({
  loader: () => import("../screens/forum/ForumCategoriesScreen"),
  loading,
});

export const ForumScreen = Loadable({
  loader: () => import("../screens/forum/ForumScreen"),
  loading,
});

export const ForumScreenFeed = Loadable({
  loader: () => import("../screens/forum/ForumScreenFeed"),
  loading,
});

export const NewsletterSubscribersScreen = Loadable({
  loader: () => import("../screens/notifications/NewsletterSubscribersScreen"),
  loading,
});
