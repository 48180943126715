import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Switch,
  Tag,
  Upload,
} from "antd";
import { getAreasOfInterestsService } from "../../services/interests";
import { uploadFileService } from "../../services/storage";
import { getUserFromStore } from "../../helpers/store";
import { notifyError, notifySuccess } from "../../helpers/notifications";
import { updateSegmentService } from "../../services/segments";
import { useSelector } from "react-redux";
import { userCan } from "../../rbac/Can";
import { beforeUpload } from "../../helpers/forms";
import Editor from "../../components/Editor";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";

const { Option } = Select;

export default Form.create({ name: "add_news_form" })(
  ({
    segment,
    form: {
      getFieldDecorator,
      validateFieldsAndScroll,
      validateFields,
      getFieldValue,
      resetFields,
    },
  }) => {


    const [loading, isLoading] = useState(false);
    const [drawerOpen, isDrawerOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState("90%");
    const [buttonDisabled, isButtonDisabled] = useState(false);


    useEffect(() => {
     
    }, []);

    const handleSubmit = (e) => {
      e.preventDefault();
      validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            isLoading(true);
            await updateSegmentService(segment.id, values);
            notifySuccess({
              title: "Ok!",
              message: "A notícia foi atualizada",
            });
            window.location.reload();
            resetFields();
            isLoading(false);
          } catch (e) {
            isLoading(false);
            notifyError(e);
          }
        }
      });
    };

    const onCancelForm = async () => {
        isDrawerOpen(false);
      };

    const IncreaseWindowSizeButton = () => {
        if (windowWidth === 520)
          return (
            <Icon
              type="fullscreen"
              onClick={() => setWindowWidth("90%")}
              style={{ marginRight: 20 }}
            />
          );
        else
          return (
            <Icon
              type="fullscreen-exit"
              onClick={() => setWindowWidth(520)}
              style={{ marginRight: 20 }}
            />
          );
      };

    return (
      <>
        <Tag
          color="blue"
          type="primary"
          onClick={() => isDrawerOpen(!drawerOpen)}
        >
          <Icon type="edit" /> Editar
        </Tag>
        <Drawer
          title={[<IncreaseWindowSizeButton />, "Editar segmento"]}
          width={windowWidth}
          onClose={onCancelForm}
          visible={drawerOpen}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={handleSubmit} layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Nome">
                  {getFieldDecorator("name", {
                    initialValue: segment.name,
                    rules: [{ required: true, message: "Insira um nome" }],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
              }}
            >
              <Button onClick={onCancelForm} style={{ marginRight: 8 }}>
                Cancelar
              </Button>
              <Button
                disabled={buttonDisabled}
                loading={loading}
                htmlType="submit"
                type="primary"
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Drawer>
      </>
    );
  }
);
