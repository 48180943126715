import { createActions, createReducer } from "reduxsauce";

const INITIAL_STATE = {
  isLoggedIn: false,
  token: "",
  user: {},
  rememberMe: false
};

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  userLoggedIn: ["user"],
  userLoggedOut: [null],
  rememberMe: ["rememberMe"]
});

/**
 * Handlers
 */
const userLoggedIn = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoggedIn: true,
    user: action.user
  };
};

const userLoggedOut = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoggedIn: false,
    user: {}
  };
};

const rememberMe = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    rememberMe: action.rememberMe
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.USER_LOGGED_IN]: userLoggedIn,
  [Types.USER_LOGGED_OUT]: userLoggedOut,
  [Types.REMEMBER_ME]: rememberMe
});
