import { takeEvery, call, put } from "redux-saga/effects";
import { Types } from "./duck";
import { getStatesService } from "../../services/state";
import { StateActions } from "../actions";

function* getStates() {
  const states = yield call(getStatesService);
  yield put(StateActions.setStates(states));
}

export default function* rootSaga() {
  yield takeEvery(Types.GET_STATES, getStates);
}
