export default {
  ADMIN: {
    static: [
      "dashboard:home",
      "discounts_club:discounts",
      "events:add",
      "events:view",
      "forum:post:add",
      "forum:posts",
      "my_profile",
      "news:add",
      "news:home",
      "notifications:newsletter",
      "users:view",
    ],
    dynamic: {
      "posts:edit": ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
    },
  },
  USER: {
    static: [
      "news_feed",
      "events_feed",
      "discounts_club_feed",
      "affiliated",
      "forum_feed",
      "user_profile",
      "user_interests",
      "discounts_club:categories",
      "discounts_club:discounts",
      "discounts_club:see_all",
    ],
  },
  SUPER_ADMIN: {
    static: [
      "dashboard:home",
      "discounts_club:categories",
      "discounts_club:discounts",
      "discounts_club:see_all",
      "events:add",
      "events:see_all",
      "events:view",
      "forum:categories",
      "forum:categories:add",
      "forum:post:add",
      "forum:posts",
      "my_profile",
      "news:add",
      "news:edit_state",
      "news:home",
      "news:interests_area",
      "news:interests_area:add",
      "news:see_all",
      "notifications:newsletter",
      "user:show_role",
      "users:add",
      "users:delete_user",
      "users:filter:state",
      "users:filter:role",
      "users:filter:city",
      "users:filter:segment",
      "users:set_validity",
      "users:view",
      "segments:view",
      "segments:add",
    ],
  },
};
