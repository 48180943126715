import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  DiscountClubCategories,
  DiscountsScreen,
  DiscountsScreenFeed,
  EventsScreen,
  EventsScreenFeed,
  ForumCategories,
  ForumScreen,
  ForumScreenFeed,
  HomeScreen,
  InterestsAreaScreen,
  NewsletterSubscribersScreen,
  NewsScreen,
  NewsScreenFeed,
  ProfileScreen,
  UserProfileScreen,
  UserInterestScreen,
  UsersScreen,
  AffiliateScreen,
} from "./screens";
import Can from "../rbac/Can";
import SegmentsScreen from "../screens/segments/SegmentsScreen";

export const routes = [
  {
    Component: NewsScreenFeed,
    hasSidebar: true,
    iconType: "snippets",
    key: "news_feed",
    label: "Notícias",
    path: "news_feed",
    ruleKey: "news_feed",
  },
  {
    Component: EventsScreenFeed,
    hasSidebar: true,
    iconType: "calendar",
    key: "events_feed",
    label: "Eventos",
    path: "events_feed",
    ruleKey: "events_feed",
  },
  {
    Component: DiscountsScreenFeed, 
    hasSidebar: true,
    iconType: "tags",
    key: "discounts_club_feed",
    label: "Descontos",
    path: "discounts_club_feed",
    ruleKey: "discounts_club_feed",
  },
  {
    Component: AffiliateScreen,
    hasSidebar: true,
    iconType: "contacts",
    key: "filiados",
    label: "Filiados",
    path: "filiados",
    ruleKey: "affiliated",
  },
  {
    Component: ForumScreenFeed,
    hasSidebar: true,
    iconType: "tags",
    key: "forum_feed",
    label: "Forum",
    path: "forum_feed",
    ruleKey: "forum_feed",
  },

  {
    Component: HomeScreen,
    hasSidebar: true,
    iconType: "home",
    key: "home",
    label: "Início",
    path: "home",
    ruleKey: "dashboard:home",
  },
  {
    Component: ProfileScreen,
    hasSidebar: false, 
    path: "my_profile",
    ruleKey: "my_profile",
  },
  {
    Component: UserProfileScreen,
    hasSidebar: false, 
    path: "user_profile",
    ruleKey: "user_profile",
  },
  {
    Component: UserInterestScreen,
    hasSidebar: false, 
    path: "area_de_interesse",
    ruleKey: "user_interests",
  },
  {
    Component: UsersScreen,
    hasSidebar: true,
    iconType: "contacts",
    key: "users",
    label: "Usuários",
    path: "users",
    exact: true,
    ruleKey: "users:view",
  },
  
  {
    Component: SegmentsScreen,
    hasSidebar: true,
    iconType: "star",
    key: "segments",
    label: "Segmentos",
    path: "segments",
    exact: true,
    ruleKey: "segments:view",
  },

  {
    Component: UsersScreen,
    key: "users/id",
    path: "users/:id",
    ruleKey: "users:view",
  },
  {
    hasSidebar: true,
    iconType: "snippets",
    key: "news",
    label: "Notícias",
    children: [
      {
        Component: NewsScreen,
        hasSidebar: true,
        key: "news",
        label: "Gerenciar",
        path: "news",
        ruleKey: "news:home",
      },
      {
        Component: InterestsAreaScreen,
        hasSidebar: true,
        key: "interests_area",
        label: "Áreas de Interesse",
        path: "interests_area",
        ruleKey: "news:interests_area",
      },
    ],
  },
  {
    Component: EventsScreen,
    hasSidebar: true,
    iconType: "calendar",
    key: "events",
    label: "Eventos",
    path: "events",
    ruleKey: "events:view",
  },
  {
    hasSidebar: true,
    iconType: "tags",
    key: "discounts_club",
    label: "Clube de Benefícios",
    children: [
      {
        Component: DiscountsScreen,
        hasSidebar: true,
        key: "discounts_club:discounts",
        label: "Descontos",
        path: "discounts",
        ruleKey: "discounts_club:discounts",
      },
      {
        Component: DiscountClubCategories,
        hasSidebar: true,
        key: "discounts_club:companies_categories",
        label: "Categorias",
        path: "companies_categories",
        ruleKey: "discounts_club:categories",
      },
    ],
  },
  {
    hasSidebar: true,
    iconType: "tags",
    key: "forum",
    label: "Fórum",
    children: [
      {
        Component: ForumScreen,
        hasSidebar: true,
        key: "forum:posts",
        label: "Posts",
        path: "forum_posts",
        ruleKey: "forum:posts",
      },
      {
        Component: ForumCategories,
        hasSidebar: true,
        key: "forum:categories",
        label: "Categorias",
        path: "forum_categories",
        ruleKey: "forum:categories",
      },
    ],
  },
  {
    hasSidebar: true,
    iconType: "notification",
    key: "notification",
    label: "Notificações",
    children: [
      {
        Component: NewsletterSubscribersScreen,
        hasSidebar: true,
        key: "notifications:newsletter",
        label: "Newsletters",
        path: "notifications_newsletter",
        ruleKey: "notifications:newsletter",
      },
    ],
  },
];

export default ({ url }) => {
  const { user } = useSelector((state) => state.auth);

  const createRoute = ({ path, exact, Component, ruleKey, children }) => {
    return (
      <Route exact={exact} key={path} path={`${url}/${path}`}>
        <Can
          role={user._role || user.role}
          perform={ruleKey}
          yes={() => <Component />}
          no={() => <Redirect to="/dashboard" />}
        />
      </Route>
    );
  };

  return routes.map((route) => {
    if (route.children) {
      return route.children.map(createRoute);
    } else {
      return createRoute(route);
    }
  });
};
