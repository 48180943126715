import React from "react";
import Can from "../../rbac/Can";
import Container from "../../components/Container";
import AddNewsForm from "../../containers/segments/NewSegmentsForm";
import SegmentsList from "../../containers/segments/SegmentsList";

export default () => {
  return (
    <Container
      pageHeaderSubtitle="Segmentos"
      pageHeaderIcon="star"
      headerTitle="Segmentos"
      extras={[
        <Can key="segments" perform="segments:add" yes={() => <AddNewsForm />} />,
      ]}
    >
      <SegmentsList />
    </Container>
  );
};
