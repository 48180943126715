import moment from "moment";
import "moment/locale/pt";
moment.locale("pt");

/* const dayjs = require('dayjs'); */

export const firestoreTimeToMoment = (firestoreTime) => {
  const date = firestoreTime.toDate();
  return new moment(date);
};

export const formattedDate = (moment, format = "DD/MM/YYYY") => {
  return moment.format(format);
};

export const lastStringChar = (string) => {
  const charsArray = string.split("");
  return charsArray[charsArray.length - 1];
};

export const sortArrayOfObjectsByKeyAndStringValue = (array, key) => {
  return array.sort(function (a, b) {
    const nameA = a[key].toUpperCase();
    const nameB = b[key].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};
