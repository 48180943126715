import { getAuthState } from "./services/auth";
import { store } from "./store";
import {
  StateActions,
  ForumActions,
  DiscountClubActions,
} from "./ducks/actions";

export default () => {
  getAuthState();
  store.dispatch(StateActions.getStates());
  store.dispatch(ForumActions.getCategories());
  store.dispatch(DiscountClubActions.getCategories());
};
