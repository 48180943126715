import React, { useEffect, useState } from "react";
import {
  Col,
  Descriptions,
  Divider,
  Icon,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import { notifyError, notifySuccess } from "../../helpers/notifications";
import Can from "../../rbac/Can";
import { getSegmentsService, deleteSegmentsService } from "../../services/segments";
import { useSelector } from "react-redux";
import EditSegmentsForm from "./EditSegments";
import { firestoreTimeToMoment, formattedDate } from "../../helpers/common";

const { Option } = Select;
const { Paragraph } = Typography;

export default () => {
  const [segment, setSegment] = useState(null);
  const [highlightsFilter, setHighlightsFilter] = useState(false);
  const [stateFilter, setStateFilter] = useState(null);
  const [loading, isLoading] = useState(false);
  const { states } = useSelector((store) => store.states);

  useEffect(() => {
    getSegments();
  }, [highlightsFilter, stateFilter]);

  const getSegments = async () => {
    try {
      isLoading(true);
      const segments = await getSegmentsService();

      setSegment(segments);
      isLoading(false);
    } catch (e) {
      isLoading(false);
      notifyError(e);
    }
  };

  const columns = [
    {
      title: "Segmento",
      dataIndex: "name",
      key: "title",
      width: "100%",
    },
    {
      title: "Ações",
      key: "actions",
      render: (segment) => (
        <>
          <EditSegmentsForm segment={segment} />
          <DeleteSegmentsButton segment={segment} onDelete={getSegments} />
        </>
      ),
    },
  ];

  return (
    <div>
      <Row gutter={24}>
        <Col>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={segment}
            loading={loading}
            rowExpandable
          />
        </Col>
      </Row>
    </div>
  );
};

const DeleteSegmentsButton = ({ segment, onDelete }) => {
  const [loading, setLoading] = useState(false);

  const deleteNews = async () => {
    try {
      setLoading(true);
      await deleteSegmentsService(segment.id);
      onDelete();
      notifySuccess({ title: "Tudo certo", message: "O segmento foi excluído" });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      notifyError(e);
    }
  };

  return (
    <Popconfirm
      title={
        <p>
          Você tem quer deletar mesmo? <br /> Esta operação não poderá ser
          desfeita
        </p>
      }
      onConfirm={deleteNews}
      okText="Sim"
      cancelText="Não"
    >
      <Tag color="red" style={{ marginTop: 5 }}>
        <Icon type={loading ? "loading" : "close"} /> Excluir
      </Tag>
    </Popconfirm>
  );
};
