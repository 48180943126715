import { call, put, takeEvery } from "redux-saga/effects";
import { Types } from "./duck";
import { DiscountClubActions } from "../actions";
import { getDiscountClubCategoriesService } from "../../services/discountClub";

function* getCategories() {
  const categories = yield call(getDiscountClubCategoriesService);
  yield put(DiscountClubActions.setCategories(categories));
}

export default function* rootSaga() {
  yield takeEvery(Types.GET_CATEGORIES, getCategories);
}
