export default {
  "auth/email-already-in-use":
    "Este email já pertence a um usuário. Use outro.",
  "auth/weak-password": "Senha deve conter mais de 6 caracteres.",
  "auth/invalid-email": "O Email está em formato errado.",
  "auth/network-request-failed": "Houve um erro na conexão com a rede.",
  "auth/wrong-password":
    "Email ou senha inválidos. Verifique os dados de acesso",
  "auth/user-not-found": "Não existe usuário com este email.",
  "permission-denied": "Você não pode realizar esta operação",
  "auth/email-already-exists": "Este email já existe na base de dados.",
  "auth/insufficient-permission":
    "Suas permissões não suficientes para esta operação",
  "auth/internal-error": "Erro Interno",
};
