import { getColData, getDoc } from "./firestore";

export const getStatesService = async () => {
  return await getColData("states", { orderBy: "name" });
};

export const getCitiesService = async (stateId) => {
  return await getColData(`states/${stateId}/cities`, { orderBy: "name" });
};

export const getSegmentsService = async () => {
  return await getColData(`segments`, { orderBy: "name" });
};

export const getSingleState = async (stateId) => {
  return await getDoc(`states/${stateId}`);
};

// export const loadFirestoreData = async () => {
//   const finalStates = states.map(state => {
//     const stateCities = [];
//     cities.forEach(city => {
//       if (city.Estado === state.ID) {
//         stateCities.push({ name: city.Nome });
//       }
//     });
//     state = {
//       name: state.Nome,
//       uf: state.Sigla,
//       cities: stateCities
//     };
//
//     return state;
//   });
//
//   let users = [];
//
//   for (const state of finalStates) {
//     const newDocRef = await addDoc(
//       "states",
//       { name: state.name, uf: state.uf },
//       {}
//     );
//     let newDoc = await newDocRef.get();
//     newDoc = { id: newDoc.id, ...newDoc.data() };
//
//     for (const city of state.cities) {
//       await addDoc(`states/${newDoc.id}/cities`, { name: city.name });
//     }
//   }
// };
