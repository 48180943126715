import { createActions, createReducer } from "reduxsauce";

const INITIAL_STATE = {
  pageHeader: null
};

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setPageHeader: ["pageHeader"]
});

/**
 * Handlers
 */
const setPageHeader = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    pageHeader: action.pageHeader
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_PAGE_HEADER]: setPageHeader
});
