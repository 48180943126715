import { firestore } from "./firebase";

export const getDocRef = (path) => firestore.doc(path);
export const getDoc = async (path) => {
  const data = await getDocRef(path).get();
  return { ...data.data(), id: data.id };
};

export const getColRef = (path, { orderBy, orderDirection = "asc" }) => {
  if (orderBy) {
    return firestore.collection(path).orderBy(orderBy, orderDirection);
  }
  return firestore.collection(path);
};

export const setDoc = async (path, data) => {
  const ref = getDocRef(path);
  return await ref.set(data);
};

export const addDoc = async (collectionPath, data) => {
  const ref = getColRef(collectionPath, {});
  return await ref.add(data);
};

export const updateDoc = async (path, data) => {
  const doc = getDocRef(path);
  return await doc.update(data);
};

export const deleteDoc = async (path) => {
  const doc = getDocRef(path);
  return await doc.delete();
};

export const createDocListener = (path, callback) => {
  const docRef = getDocRef(path);
  docRef.onSnapshot((doc) => callback({ ...doc.data(), id: doc.id }));
};

export const createColListener = (path, callback, { orderBy }) => {
  const colRef = getColRef(path, { orderBy });
  colRef.onSnapshot((snapshot) => {
    const data = [];
    snapshot.docs.forEach((item) => data.push({ id: item.id, ...item.data() }));

    callback(data);
  });
};

export const createColListenerWhere = (
  path,
  callback,
  { orderBy, where: { field, operator, value } }
) => {
  let ref;
  if (orderBy)
    ref = firestore
      .collection(path)
      .orderBy(orderBy)
      .where(field, operator, value);
  else ref = firestore.collection(path).where(field, operator, value);

  ref.onSnapshot((snapshot) => {
    const data = [];
    snapshot.docs.forEach((item) => data.push({ id: item.id, ...item.data() }));
    callback(data);
  });
};

export const getColData = async (path, { orderBy, orderDirection }) => {
  const col = await getCol(path, { orderBy, orderDirection });
  const data = [];

  col.forEach((item) => data.push({ id: item.id, ...item.data() }));

  return data;
};

export const getCol = async (path, { orderBy, orderDirection }) => {
  const col = getColRef(path, { orderBy, orderDirection });
  return await col.get();
};

export const getColWhereRef = (
  path,
  { orderBy, where: { field, operator, value } }
) => {
  let ref;
  if (orderBy)
    ref = firestore
      .collection(path)
      .orderBy(orderBy)
      .where(field, operator, value);
  else ref = firestore.collection(path).where(field, operator, value);
  return ref;
};

export const getColDataWhere = async (
  path,
  { orderBy, orderDirection = "asc", where: { field, operator, value } }
) => {
  let ref;
  if (orderBy)
    ref = firestore
      .collection(path)
      .orderBy(orderBy, orderDirection)
      .where(field, operator, value);
  else ref = firestore.collection(path).where(field, operator, value);
  const col = await ref.get();
  const data = [];

  col.forEach((item) => data.push({ id: item.id, ...item.data() }));

  return data;
};
