import { getColData, getDoc } from "./firestore";
import { functions } from "./firebase";

const SINGLE_AREA_OF_INTEREST_PATH = (areaId) => `areas_of_interest/${areaId}`;

export const getAreasOfInterestsService = async () => {
  return await getColData("areas_of_interest", { orderBy: "name" });
};

export const getSingleAreaOfInterestsService = async (areaId) => {
  return await getDoc(SINGLE_AREA_OF_INTEREST_PATH(areaId));
};

export const updateAreaOfInterestService = async (area, value) => {
  const funcUpdate = functions.httpsCallable("updateAreaOfInterest");
  const result = await funcUpdate({ area, newData: value });
  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};

export const deleteAreaOfInterestService = async (area) => {
  const funcDelete = functions.httpsCallable("deleteAreaOfInterest");
  const result = await funcDelete({ area });
  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};
