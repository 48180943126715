import State from "./stateModel";

export default class User {
  constructor({
    id,
    name,
    role,
    email,
    state,
    aboutMe,
    billing_average,
    business,
    cellphone,
    city,
    cityName,
    cnpj,
    cpf,
    employees,
    phone,
    photoURL,
    stateUf,
    strongPoints,
    entrepreneurDoubts,
    isSuspended,
    interests,
    public_phone,
    instagram,
    public_instagram,
  }) {
    this._id = id;
    this._name = name;
    this._role = role;
    this._state = state && new State(state);
    this._aboutMe = aboutMe;
    this._billing_average = billing_average;
    this._business = business;
    this._cellphone = cellphone;
    this._cnpj = cnpj;
    this._cpf = cpf;
    this._employees = employees;
    this._phone = phone;
    this._photoURL = photoURL;
    this._strongPoints = strongPoints;
    this._entrepreneurDoubts = entrepreneurDoubts;
    this._city = city;
    this._cityName = cityName;
    this._stateUf = stateUf;
    this._email = email;
    this._isSuspended = isSuspended;
    this._interests = interests;
    this._public_phone = public_phone;
    this._instagram = instagram;
    this._public_instagram = public_instagram;
  }

  get city() {
    return this._city;
  }

  static fromJson(json) {
    return new User(json);
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get role() {
    return this._role;
  }

  get state() {
    return this._state;
  }

  get aboutMe() {
    return this._aboutMe;
  }

  get billing_average() {
    return this._billing_average;
  }

  get business() {
    return this._business;
  }

  get cellphone() {
    return this._cellphone;
  }

  get cnpj() {
    return this._cnpj;
  }

  get cpf() {
    return this._cpf;
  }

  get employees() {
    return this._employees;
  }

  get phone() {
    return this._phone;
  }

  get photoURL() {
    return this._photoURL;
  }

  get strongPoints() {
    return this._strongPoints;
  }

  get entrepreneurDoubts() {
    return this._entrepreneurDoubts;
  }

  set city(value) {
    this._city = value;
  }

  get cityName() {
    return this._cityName;
  }

  get stateUf() {
    return this._stateUf;
  }

  get email() {
    return this._email;
  }

  get isSuspended() {
    return this._isSuspended;
  }
  get interests() {
    return this._interests;
  }

  get public_phone() {
    return this._public_phone;
  }

  get instagram() {
    return this._instagram;
  }

  get public_instagram() {
    return this._public_instagram;
  }

  toJson() {
    return {
      id: this._id,
      name: this._name,
      photoURL: this._photoURL,
      stateUf: this._stateUf,
      cityName: this._cityName,
    };
  }
}
