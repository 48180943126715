import { createActions, createReducer } from "reduxsauce";

const INITIAL_STATE = {
  categories: [],
};

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setCategories: ["categories"],
  getCategories: [null],
});

/**
 * Handlers
 */
const setCategories = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    categories: action.categories,
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_CATEGORIES]: setCategories,
});
