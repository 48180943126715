import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import { history } from "../store";
import { SignInScreen, UserSignInScreen, UserSignUpScreen } from "./screens";
import App from "../containers/App/App";
import UserApp from "../containers/App/UserApp";
/* import { getAuthState } from "../services/auth"; */
import bootstrap from "../bootstrap";

function RoutesContainer() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/">
          <UserSignInScreen />
        </Route>
        <Route exact path="/login">
          <UserSignInScreen />
        </Route>
        <Route exact path="/registro">
          <UserSignUpScreen />
        </Route>
        <Route exact path="/admin">
          <SignInScreen />
        </Route>
        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/web"
          component={UserApp}
          isLoggedIn={isLoggedIn}
        />
      </Switch>
    </ConnectedRouter>
  );
}

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default RoutesContainer;
