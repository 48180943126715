import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Icon,
  Input,
  Row,
  Select,
} from "antd";

import { notifyError, notifySuccess } from "../../helpers/notifications";
import { createNewSegmentsService } from "../../services/segments";

const { Option } = Select;

export default Form.create({ name: "new_user_form" })(
  ({
    form: {
      getFieldDecorator,
      validateFieldsAndScroll,
      validateFields,
      getFieldValue,
    },
  }) => {
    const [drawerOpen, isDrawerOpen] = useState(false);
    const [loading, isLoading] = useState(false);

    useEffect(() => {
     
    }, []);


    const handleSubmit = (e) => {
      e.preventDefault();
      validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            isLoading(true);
            console.log(values);
            await createNewSegmentsService(values);
            notifySuccess({
              title: "Tudo certo",
              message: "Um novo acesso foi inserido",
            });
            isLoading(false);
          } catch (e) {
            isLoading(false);
            notifyError(e);
          }
        }
      });
    };

    return (
      <>
        <Button type="primary" onClick={() => isDrawerOpen(!drawerOpen)}>
          <Icon type="plus" /> Novo segmento
        </Button>
        <Drawer
          title="Novo segmento"
          width={520}
          onClose={() => isDrawerOpen(false)}
          visible={drawerOpen}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form onSubmit={handleSubmit} layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Nome">
                  {getFieldDecorator("name", {
                    rules: [{ required: true, message: "Insira um segmento" }],
                  })(<Input />)}
                </Form.Item>      
              </Col>
            </Row>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
              }}
            >
              <Button
                onClick={() => isDrawerOpen(false)}
                style={{ marginRight: 8 }}
              >
                Cancelar
              </Button>
              <Button loading={loading} htmlType="submit" type="primary">
                Salvar
              </Button>
            </div>
          </Form>
        </Drawer>
      </>
    );
  }
);
