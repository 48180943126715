import { notification } from "antd";
import firebaseErrors from "./errorsTranslate";

export const notifyError = (e) => {
  let message = firebaseErrors[e.code] || e.message;

  notification["error"]({
    message: "Houve um erro",
    description: e.description || message,
    duration: 0,
  });
};

export const notifySuccess = ({ title, message }) => {
  notification["success"]({
    message: title,
    description: message,
  });
};

export const notifyInfo = (e) => {
  notification["info"]({
    message: e.title,
    description: e.message,
  });
};
