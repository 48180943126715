import { storage } from "./firebase";

export const uploadFileService = ({
  onSuccessCallback,
  onError,
  file,
  onProgress,
  url,
}) => {
  return new Promise(async (resolve, reject) => {
    let extension = getFileExtension(file.name);
    let fileName = getFileName(extension);
    let fileRef = storage.ref(`${url}/${fileName}`);
    let task = fileRef.put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        resolve(onProgress({ percent: percentage }));
      },
      function error(err) {
        reject(onError(err));
      },
      async function complete() {
        let downloadURL = await task.snapshot.ref.getDownloadURL();
        resolve(onSuccessCallback(downloadURL, fileName));
      }
    );
  });
};

export const deleteImageFromUrlService = async (imageUrl) => {
  const ref = storage.refFromURL(imageUrl);
  await ref.delete();
};

const getFileExtension = (fileName) =>
  /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName) : undefined;

const getFileName = (extension) => `${new Date().getTime()}.${extension}`;

export const getStorageRef = (child) => storage.ref(child);
