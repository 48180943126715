import React, { useState } from "react";
import { Icon, Layout, Menu } from "antd";
import RestrictedRoutes, { routes } from "../../routes/RestrictedRoutes";
import { Link } from "react-router-dom";
import { userCan } from "../../rbac/Can";
import UserHeader from "./UserHeader";
import somaBrand from "../../assets/imgs/positive_brand.svg";
import somaIcon from "../../assets/imgs/soma_icon.svg";
import { getUserFromStore } from "../../helpers/store";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default ({ match, location }) => {
  const [collapsed, isCollapsed] = useState(false);

  const mountItem = ({
    key,
    path,
    iconType,
    label,
    ruleKey,
    hasSidebar,
    children,
  }) => { 
    if (userCan({ perform: ruleKey })) {
      return (
        <Menu.Item key={key}>
          <Link to={`/web/${path}`}>
            <Icon type={iconType} />
            <span>{label}</span>
          </Link>
        </Menu.Item>
      );
    }
  };
  
  return (
    <Layout className="root_layout">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Link to={`/web/news_feed`} className="logo">
          {collapsed ? (
              <img src={somaIcon} alt="" />
          ) : (
              <img src={somaBrand} alt="" />
          )}
        </Link>
        { !getUserFromStore()._isSuspended &&
          <Menu theme="dark" mode="inline" selectedKeys={location.pathname}>
            {routes.map((data) => data.hasSidebar && mountItem(data))}
          </Menu>
        }
      </Sider>
      <Layout>
        <UserHeader
          collapsed={collapsed}
          handleCollapsed={() => isCollapsed(!collapsed)}
        />
        <RestrictedRoutes url={match.url} />
      </Layout>
    </Layout>
  );
};
