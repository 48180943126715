import React from "react";
import { applyMiddleware, compose, createStore } from "redux";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import rootReducer from "./ducks/reducers";
import sagas from "./ducks/sagas";
import RoutesContainer from "./routes/RoutesContainer";

const persistConfig = {
  key: "appsoma",
  storage,
};
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const connectedRouterReducer = rootReducer(history);
const persistedReducer = persistReducer(persistConfig, connectedRouterReducer);

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RoutesContainer />
    </PersistGate>
  </Provider>
);
