import React, { useState } from "react";
import { Icon, Layout, Menu } from "antd";
import RestrictedRoutes, { routes } from "../../routes/RestrictedRoutes";
import { Link } from "react-router-dom";
import { userCan } from "../../rbac/Can";
import Header from "./Header";
import somaBrand from "../../assets/imgs/positive_brand.svg";
import somaIcon from "../../assets/imgs/soma_icon.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default ({ match, location }) => {
  const [collapsed, isCollapsed] = useState(false);

  const mountItem = ({
    key,
    path,
    iconType,
    label,
    ruleKey,
    hasSidebar,
    children,
  }) => {
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span>
              <Icon type={iconType} />
              <span>{label}</span>
            </span>
          }
        >
          {children.map(({ ruleKey, key, path, iconType, label }) => {
            if (userCan({ perform: ruleKey })) {
              return (
                <Menu.Item key={key}>
                  <Link to={`/dashboard/${path}`}>
                    <span>{label}</span>
                  </Link>
                </Menu.Item>
              );
            }
          })}
        </SubMenu>
      );
    }

    if (userCan({ perform: ruleKey })) {
      return (
        <Menu.Item key={key}>
          <Link to={`/dashboard/${path}`}>
            <Icon type={iconType} />
            <span>{label}</span>
          </Link>
        </Menu.Item>
      );
    }
  };

  return (
    <Layout className="root_layout">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          {collapsed ? (
            <img src={somaIcon} alt="" />
          ) : (
            <img src={somaBrand} alt="" />
          )}
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={location.pathname}>
          {routes.map((data) => data.hasSidebar && mountItem(data))}
        </Menu>
      </Sider>
      <Layout>
        <Header
          collapsed={collapsed}
          handleCollapsed={() => isCollapsed(!collapsed)}
        />
        <RestrictedRoutes url={match.url} />
      </Layout>
    </Layout>
  );
};
