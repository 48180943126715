import firebase from "firebase";
import "firebase/firestore";
import "firebase/functions";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

export const fireAuth = firebaseApp.auth();

export const database = firebaseApp.database();
export const storage = firebaseApp.storage();

const functions = firebaseApp.functions();
const firestore = firebaseApp.firestore();

//todo adicionar esse bloco de codigo para sumular o banco firebase online
/* if (process.env.NODE_ENV === "development") {
  firestore.settings({
    host: "localhost:3000",
    ssl: false,
  });

  functions.useFunctionsEmulator("http://localhost:5001");
} */

export { firestore, functions };
