import { fireAuth } from "./firebase";
import { store } from "../store";
import { AuthActions } from "../ducks/actions";
import { createUserInfoListenerService, getUserInfo } from "./user";
import rolesEnum from "../models/rolesEnum";

export const getAuthState = () => {
  fireAuth.onAuthStateChanged(async (loggedUser) => {
    if (loggedUser) {
      await syncUserInfo(loggedUser.uid);
    } else {
      store.dispatch(AuthActions.userLoggedOut());
    }
  });
};

export const syncUserInfo = (userId) => {
  createUserInfoListenerService(userId, (user) => {
    store.dispatch(AuthActions.userLoggedIn(user));
  });
};

export const signInWithEmailPassword = async (email, password) => {
  const { user } = await fireAuth.signInWithEmailAndPassword(email, password);
  await loadUserInfo(user);
};
export const signInUserWithEmailPassword = async (email, password) => {
  const { user } = await fireAuth.signInWithEmailAndPassword(email, password);
  await loadBasicUserInfo(user);
};

export const signOut = async () => {
  await fireAuth.signOut();
  store.dispatch(AuthActions.userLoggedOut());
};
//* load user info for the adm route
const loadUserInfo = async (user) => {
  const userInfo = await getUserInfo(user.uid);
  if (
    userInfo.role !== rolesEnum.ADMIN &&
    userInfo.role !== rolesEnum.SUPER_ADMIN
  ) {
    await fireAuth.signOut();
    throw { message: "Você não pode realizar o login" };
  }
  store.dispatch(AuthActions.userLoggedIn(userInfo));
};
//* load user info for the basic user/client
const loadBasicUserInfo = async (user) => {
  const userInfo = await getUserInfo(user.uid);
  store.dispatch(AuthActions.userLoggedIn(userInfo));
};

export const sendPasswordResetEmail = async ({ email }) => {
  return await fireAuth.sendPasswordResetEmail(email);
};
