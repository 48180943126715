import {
  createDocListener,
  getColRef,
  getColWhereRef,
  getDoc,
  setDoc,
  updateDoc,
} from "./firestore";
import User from "../models/userModel";
import { firestore, functions } from "./firebase";
import { getUserFromStore } from "../helpers/store";
import rolesEnum from "../models/rolesEnum";
import { fireAuth } from "./firebase";
import { syncUserInfo } from "./auth";
import { store } from "../store";
import { AuthActions } from "../ducks/actions";

const USER_ENDPOINT = "users/";
const USER_PATH = (userId) => `users/${userId}`;

export const getUserInfo = async (idUser) => {
  const user = await getDoc(`${USER_ENDPOINT}${idUser}`);
  return await mountUser(user);
};

export const getUserData = async (idUser) => {
  const user = await getDoc(`${USER_ENDPOINT}${idUser}`);
  return user;
};

export const createUserInfoListenerService = (userId, callback) => {
  return createDocListener(USER_PATH(userId), async (data) => {
    const user = await mountUser(data);
    callback(user);
  });
};

const getUsersColRef = () => {
  const loggedUser = getUserFromStore();
  let usersCol;
  if (loggedUser._role === rolesEnum.SUPER_ADMIN)
    usersCol = getColRef("users", { orderBy: "name" });
  else
    usersCol = getColWhereRef("users", {
      orderBy: "name",
      where: {
        field: "state",
        operator: "==",
        value: loggedUser._state._id,
      },
    });

  return usersCol;
};

export const createUsersListenerService = (
  { filters: { isSuspended, stateId, role, cpf, cnpj }, startAfter },
  callback
) => {
  const user = getUserFromStore();
  let colPath = firestore.collection(USER_ENDPOINT).orderBy("name", "asc");

  if (!isSuperAdmin(user)) {
    stateId = user._state._id;
  }

  if (stateId !== null) colPath = colPath.where("state", "==", stateId);
  if (role && isSuperAdmin(user)) colPath = colPath.where("role", "==", role);
  if (isSuspended !== null)
    colPath = colPath.where("isSuspended", "==", isSuspended);

  if (cpf) colPath = colPath.where("cpf", "==", cpf);
  if (cnpj) colPath = colPath.where("cnpj", "==", cnpj);

  colPath.onSnapshot((snapshot) => {
    const data = [];
    snapshot.docs.forEach((item) => data.push({ id: item.id, ...item.data() }));

    callback(data);
  });
};
export const showUsersListenerService = (
  { filters: { isSuspended, stateId, cityId, role, cpf, cnpj, segment }, startAfter },
  callback
) => {
  const user = getUserFromStore();
  let colPath = firestore.collection(USER_ENDPOINT);

  /* if (!isSuperAdmin(user)) {
    stateId = user._state._id;
  } */

  if (stateId !== null) colPath = colPath.where("state", "==", stateId);
  if (segment !== null) colPath = colPath.where("segment", "==", segment);
  if (cityId !== null) colPath = colPath.where("city", "==", cityId);
  if (role && isSuperAdmin(user)) colPath = colPath.where("role", "==", role);
  if (isSuspended !== null)
    colPath = colPath.where("isSuspended", "==", isSuspended);

  if (cpf) colPath = colPath.where("cpf", "==", cpf);
  if (cnpj) colPath = colPath.where("cnpj", "==", cnpj);

  colPath.orderBy("name", "desc");

  colPath.onSnapshot((snapshot) => {
    const data = [];
    snapshot.docs.forEach((item) => data.push({ id: item.id, ...item.data() }));

    callback(data);
  });
};

export const getUsersService = async ({ search, state }) => {
  let usersCol = await getUsersColRef();
  if (search && search !== "")
    usersCol = usersCol
      .orderBy("phone")
      .startAt(search)
      .endAt(search + "\uf8ff");

  if (state && state !== "") usersCol = usersCol.where("state", "==", state);

  const usersResult = await usersCol.get();

  let users = [];

  for (const [, doc] of usersResult.docs.entries()) {
    const user = await mountUser({ ...doc.data(), id: doc.id });
    users.push(user);
  }

  return users;
};

const mountUser = async (userData) => {
  if (userData.state) {
    userData.state = await getDoc(`states/${userData.state}`);
  }
  return User.fromJson(userData);
};

export const updateUserService = async (userId, data) => {
  if (data.city && data.state) {
    const city = await getDoc(`states/${data.state}/cities/${data.city}`);
    data.cityName = city.name;
  } else {
    data.cityName = "";
    data.city = "";
  }

  if (data.state) {
    const state = await getDoc(`states/${data.state}`);
    data.stateUf = state.uf;
  } else {
    data.state = "";
    data.stateUf = "";
  }
  return await updateDoc(USER_PATH(userId), data);
};

export const createNewUser = async (user) => {
  const userData = {
    name: user.displayName || "",
    email: user.email,
    phoneNumber: user.phoneNumber,
    photoURL: user.photoURL,
    id: user.uid,
    uid: user.uid,
    role: rolesEnum.USER,
    isSuspended: true,
    lastSignInTime: new Date(user.metadata.lastSignInTime),
    creationTime: new Date(user.metadata.creationTime),
  };

  return await setDoc(USER_PATH(userData.id), userData);
};

export const createUserWithEmailAndPassword = async ({ email, password }) => {
  const {
    additionalUserInfo,
    user,
  } = await fireAuth.createUserWithEmailAndPassword(email, password);

  if (additionalUserInfo.isNewUser) {
    await createNewUser(user);
  }

  syncUserInfo(user.uid);

  store.dispatch(AuthActions.isAuthenticated());

  return await updateUserService(user.uid, {
    lastSignInTime: new Date(user.metadata.lastSignInTime),
    creationTime: new Date(user.metadata.creationTime),
    email,
  });
};

export const createNewUserService = async (values) => {
  const funcUpdate = functions.httpsCallable("createUser");
  const result = await funcUpdate(values);
  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};

export const deleteUserService = async (userId) => {
  const funcDelete = functions.httpsCallable("deleteUser");
  const result = await funcDelete({ userId });
  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};

export const updateEmailService = async (userId, email) => {
  const funcDelete = functions.httpsCallable("updateUserData");
  const result = await funcDelete({ userId, newUserData: { email } });

  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};

export const isSuperAdmin = (user) => user._role === "SUPER_ADMIN";

export const sendEmailOnUserApprovalService = async (userId) => {
  const funcEmail = functions.httpsCallable("sendEmailOnUserApproval");
  const result = await funcEmail({ userId });

  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};
