import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default ({ value = EditorState.createEmpty(), onChange }) => (
  <Editor
    editorState={value}
    toolbar={{
      options: [
        "inline",
        "blockType",
        "fontSize",
        "list",
        "textAlign",
        "link",
        "image",
        "history",
      ],

      inline: {
        options: ["bold", "italic", "underline", "strikethrough"],
      },
      list: { options: ["unordered", "ordered"] },
      blockType: {
        options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
      },
      image: {
        uploadEnabled: false,
        previewImage: false,
        inputAccept: "image/jpeg,image/jpg,image/png",
      },
    }}
    expanded
    stripPastedStyles
    wrapperClassName="demo-wrapper"
    editorClassName="demo-editor"
    onEditorStateChange={onChange}
  />
);
