import { getColData,  getColRef, getDocRef } from "./firestore";

const SEGMENTS_ENDPOINT = "segments/";

export const getSegmentsService = async () => {
    return await getColData(`segments`, {orderBy: "name"});
  };

  export const createNewSegmentsService = async (values) => {
  
    const newsRef = getColRef(SEGMENTS_ENDPOINT, {});
    return await newsRef.add(values);
  };

  export const deleteSegmentsService = async (newsId) => {
    const newsRef = getDocRef(`${SEGMENTS_ENDPOINT}${newsId}`);
    return await newsRef.delete();
  };

  export const updateSegmentService = async (segmentId, values) => {
    const newsRef = getDocRef(`${SEGMENTS_ENDPOINT}${segmentId}`);
    return await newsRef.update(values);
  };
