import { store } from "../store";

export const getUserFromStore = () => {
  const { auth } = store.getState();
  return auth.user;
};

export const getStatesWithTextAndValue = () => {
  const { states } = store.getState();
  return states.states.map((state) => ({ text: state.uf, value: state.id }));
};
