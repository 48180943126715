import { call, put, takeEvery } from "redux-saga/effects";
import { Types } from "./duck";
import { ForumActions } from "../actions";
import { getForumCategoriesService } from "../../services/forum/forumCategories";

function* getCategories() {
  const categories = yield call(getForumCategoriesService);
  yield put(ForumActions.setCategories(categories));
}

export default function* rootSaga() {
  yield takeEvery(Types.GET_CATEGORIES, getCategories);
}
