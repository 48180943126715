import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import users from "./users/ducks";
import app from "./app/duck";
import auth from "./auth/duck";
import states from "./states/duck";
import forum from "./forum/duck";
import discountClub from "./discountClub/duck";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    states,
    users,
    forum,
    discountClub,
  });
