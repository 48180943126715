import { getColRef, getDoc, getDocRef, addDoc, getColData, createColListener } from "../firestore";
import { firestore } from "../firebase";
import { getUserFromStore } from "../../helpers/store";
import {
  getSingleForumCategory,
  updateForumCategoryStateQuantity,
} from "./forumCategories";
import { getSingleState } from "../state";

const FORUM_CATEGORIES_PATH = "forum_categories";
const FORUM_PATH = "forum";

export const INCREASE_OPERATION = "increase";
export const DECREASE_OPERATION = "decrease";
export const INCLUDE_OPERATION = 'include';
export const EXCLUDE_OPERATION = 'exclude';

export const createPostsListener = (
  { filters: { isActive, stateId, categoryId }, startAfter },
  callback
) => {
  let colPath = firestore.collection(FORUM_PATH);

  if (isActive === true || isActive === false)
    colPath = colPath.where("isActive", "==", isActive);
  if (stateId) colPath = colPath.where("state", "==", stateId);
  if (categoryId !== "all")
    colPath = colPath.where("category.id", "==", categoryId);

  if (startAfter) colPath.startAfter(startAfter);

  colPath.orderBy("createdAt", "desc");
  console.log("colPath");
  console.log(colPath);
  colPath.onSnapshot((snapshot) => {
    const data = [];
    snapshot.docs.forEach((item) => data.push({ id: item.id, ...item.data() }));

    callback(data);
  });
};
export const updateLikesService = async (postId, likes) => {
  const newsRef = getDocRef(`${FORUM_PATH}/${postId}`);
  return await newsRef.update({likes, likesQuantity: likes.length || 0});
};

const POST_REPLIES_PATH = postId => `${FORUM_PATH}/${postId}/replies`;

export const createPostRepliesListenerService = (postId, callback) => {
  createColListener(POST_REPLIES_PATH(postId), callback, {
    orderBy: 'createdAt',
    orderDirection: 'desc',
  });
};


export const getActiveForumPostsByCategoryService = async (
  categoryId,
  stateId,
) => {
  var rawData =  await getColData(FORUM_PATH, {
    orderBy: "createdAt",
    orderDirection: "desc",
    where: [{ field: "isActive", operator: "==", value: true }, { field: "state", operator: "==", value: stateId }, { field: "category.id", operator: "==", value: categoryId }],
  });
  var data = [];

  rawData.forEach(element => {
    if(element.category.id === categoryId){
      data.push(element);
    }
  });

  if(categoryId === "all"){
    return rawData;
  }
  return data;
};

export const createForumPostService = async (values) => {
  const newForumPost = await mountForumPostObject(values);

  await updateForumCategoryStateQuantity(
    newForumPost.state,
    newForumPost.category.id,
    INCREASE_OPERATION
  );

  const forumRef = getColRef(FORUM_PATH, {});
  return await forumRef.add(newForumPost);
};

export const getSingleForumPost = async (postId) => {
  return await getDoc(`${FORUM_PATH}/${postId}`);
};
export const updateForumPostDataService = async (postId, newPostData) => {
  const postRef = getDocRef(`${FORUM_PATH}/${postId}`);
  return await postRef.update(newPostData);
};

export const updateForumPostService = async (postId, values) => {
  const isSameCategory = (oldPostCategoryId, newPostCategoryId) =>
    oldPostCategoryId === newPostCategoryId;

  const hadCategory = (oldPost) => oldPost.category;

  const isGoingToBeInactive = (oldPostData) =>
    oldPostData.isActive === true && values.isActive === false;
  const isGoingToBeActive = (oldPostData) =>
    oldPostData.isActive === false && values.isActive === true;
  const wasAlreadyActive = (oldPostData) =>
    oldPostData.isActive === true && values.isActive === true;

  const isSameState = (oldPostData, newPostData) =>
    oldPostData.state === newPostData.state;

  const updateForumPostCategory = async (forumPost, operation) => {
    await updateForumCategoryStateQuantity(
      forumPost.state,
      forumPost.category.id,
      operation
    );
  };

  const oldForumPost = await getSingleForumPost(postId);
  const newForumPost = await mountForumPostObject(values);
  newForumPost.isActive = values.isActive;
  delete newForumPost.createdAt;
  newForumPost.updatedAt = new Date();

  if (oldForumPost.category && newForumPost.category) {
    if (
      (wasAlreadyActive(oldForumPost) || isGoingToBeActive(oldForumPost)) &&
      !isSameCategory(oldForumPost.category.id, newForumPost.category.id)
    ) {
      await updateForumPostCategory(oldForumPost, DECREASE_OPERATION);
      await updateForumPostCategory(newForumPost, INCREASE_OPERATION);
    } else if (
      isGoingToBeActive(oldForumPost) &&
      isSameCategory(oldForumPost.category.id, newForumPost.category.id)
    ) {
      await updateForumPostCategory(newForumPost, INCREASE_OPERATION);
    }
  }

  if (!hadCategory(oldForumPost)) {
    await updateForumPostCategory(newForumPost, INCREASE_OPERATION);
  }

  if (isGoingToBeInactive(oldForumPost)) {
    await updateForumPostCategory(newForumPost, DECREASE_OPERATION);
  }

  if (!isSameState(oldForumPost, newForumPost)) {
    await updateForumPostCategory(oldForumPost, DECREASE_OPERATION);
    await updateForumPostCategory(newForumPost, INCREASE_OPERATION);
  }

  const forumRef = getDocRef(`${FORUM_PATH}/${postId}`);
  return await forumRef.update(newForumPost);
};

export const deleteForumPostService = async (postId) => {
  const post = await getSingleForumPost(postId);

  if (post.category) {
    await updateForumCategoryStateQuantity(
      post.state,
      post.category.id,
      DECREASE_OPERATION
    );
  }

  const postRef = getDocRef(`${FORUM_PATH}/${postId}`);
  return await postRef.delete();
};

const mountForumPostObject = async (post) => {
  const author = mountAuthor();
  const category = await getSingleForumCategory(post.category);
  const state = await getSingleState(post.stateId);

  return {
    ...post,
    category: {
      id: category.id,
      name: category.name,
    },
    author,
    state: post.stateId,
    stateDetails: state,
    isActive: true,
    createdAt: new Date(),
  };
};

export const mountAuthor = () => {
  const user = getUserFromStore();

  return {
    id: user.id,
    name: user.name,
    state: user.state.id,
    stateDetails: user.state.toObject(),
    photoURL: user.photoURL,
    business: user.business,
  };
};


/* //! -------------------------------------------------------------- */
/* //* reply area --------------------------------------------------- */
/* //! -------------------------------------------------------------- */


export const updatePostRepliesCounter = async (postId, operation) => {
  const post = await getSingleForumPost(postId);

  let postrepliesCounter = post.repliesCounter || 0;

  if (operation === INCREASE_OPERATION) {
    postrepliesCounter = postrepliesCounter + 1;
  }

  if (operation === DECREASE_OPERATION) {
    postrepliesCounter = postrepliesCounter + 1;
  }
  return await updateForumPostDataService(postId, {
    repliesCounter: postrepliesCounter,
  });
};
export const updatePostInteractionCounter = async (postId, operation) => {
  const post = await getSingleForumPost(postId);
  let postInteractionCounter = post.interactionCounter || 0;

  if (operation === INCREASE_OPERATION) {
    postInteractionCounter = postInteractionCounter + 1;
  }

  if (operation === DECREASE_OPERATION) {
    postInteractionCounter = postInteractionCounter + 1;
  }

  return await updateForumPostDataService(postId, {
    interactionCounter: postInteractionCounter,

  });
};

export const updatePostInteractionPeople = async (postId, operation) => {
  const post = await getSingleForumPost(postId);
  const author = mountAuthor();

  let postInteractionPeople = post.interactionPeople || [];

  if (
    operation === 'include' &&
    !postInteractionPeople.some(person => person.id === author.id)
  ) {
    postInteractionPeople.push(author);
    await updatePostInteractionCounter(postId, INCREASE_OPERATION);
  }

  if (
    operation === 'exclude' &&
    postInteractionPeople.some(person => person.id === author.id)
  ) {
    postInteractionPeople = postInteractionPeople.filter(
      person => person.id !== author.id,
    );

    await updatePostInteractionCounter(postId, DECREASE_OPERATION);
  }

  return await updateForumPostDataService(postId, {
    interactionPeople: postInteractionPeople,

  });
};

export const replyMainPostService = async (postId, response) => {
  response.author = mountAuthor();
  response.createdAt = new Date();
  
  await updatePostRepliesCounter(postId, INCREASE_OPERATION);
  await updatePostInteractionPeople(postId, INCLUDE_OPERATION);

  return addDoc(POST_REPLIES_PATH(postId), response);

/*   const forumRef = getColRef(FORUM_PATH, {});
  return await forumRef.add(newForumPost);

  return await getDoc(`${FORUM_PATH}/${postId}`);

  const POST_REPLIES_PATH = postId => `${FORUM_PATH}/${postId}/replies`; */

};
