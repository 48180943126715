import {
  createColListener,
  createColListenerWhere,
  getColData,
  getColRef,
  getDoc,
  getDocRef,
} from "./firestore";
import { firestore, functions } from "./firebase";
import { getUserFromStore } from "../helpers/store";
import { getSingleState } from "./state";
import { userCan } from "../rbac/Can";

const DISCOUNT_CLUB_CATEGORIES_URL = "discounts_club_categories";
const DISCOUNTS_ENDPOINT_URL = "discounts_club";

export const createNewDiscountClubCategory = async (values) => {
  const newsRef = getColRef(DISCOUNT_CLUB_CATEGORIES_URL, {});
  return await newsRef.add(values);
};

export const getSingleCategory = async (categoryId) => {
  return await getDoc(`${DISCOUNT_CLUB_CATEGORIES_URL}/${categoryId}`);
};

export const getDiscountClubCategoriesService = async () => {
  return await getColData(DISCOUNT_CLUB_CATEGORIES_URL, { orderBy: "name" });
};

export const createDiscountClubCategoriesListener = (callback) => {
  createColListener(DISCOUNT_CLUB_CATEGORIES_URL, callback, {
    orderBy: "name",
  });
};

export const updateDiscountClubCategoryService = async (category, value) => {
  const funcUpdate = functions.httpsCallable("updateDiscountClubCategory");
  const result = await funcUpdate({ category, newData: value });
  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};

export const deleteDiscountClubCategoryService = async (category) => {
  const funcDelete = functions.httpsCallable("deleteDiscountClubCategory");
  const result = await funcDelete({ category });
  if (result.data.code === 200) {
    return { title: "Sucesso", message: result.data.message };
  } else {
    throw { code: result.data.code, message: result.data.message };
  }
};

export const createDiscountService = async (values) => {
  const newDiscount = await mountDiscountObject(values);

  await updateCategoryStateQuantity(values.state, values.category, "increase");

  const newsRef = getColRef(DISCOUNTS_ENDPOINT_URL, {});
  return await newsRef.add(newDiscount);
};

export const editDiscountService = async (discountId, values) => {
  const actualDiscount = await getDoc(
    `${DISCOUNTS_ENDPOINT_URL}/${discountId}`
  );

  await updateCategoryStateQuantity(
    actualDiscount.state,
    actualDiscount.categoryId,
    "decrease"
  );

  const newDiscount = await mountDiscountObject(values);

  if (!values.disabled) {
    await updateCategoryStateQuantity(
      newDiscount.state,
      newDiscount.categoryId,
      "increase"
    );
  }

  const newsRef = getDocRef(`${DISCOUNTS_ENDPOINT_URL}/${discountId}`);
  return await newsRef.update(newDiscount);
};

export const deleteDiscountService = async (discountId) => {
  const actualDiscount = await getDoc(
    `${DISCOUNTS_ENDPOINT_URL}/${discountId}`
  );
  await updateCategoryStateQuantity(
    actualDiscount.state,
    actualDiscount.categoryId,
    "decrease"
  );

  const newsRef = getDocRef(`${DISCOUNTS_ENDPOINT_URL}/${discountId}`);
  return await newsRef.delete();
};

const mountDiscountObject = async (values) => {
  const user = getUserFromStore();
  const categoryDetails = await getSingleCategory(values.category);
  const stateDetails = await getSingleState(values.state);

  return {
    disabled: values.disabled || false,
    discount: values.discount,
    discount_description: values.discount_description,
    company: values.company,
    brand: values.brand,
    categoryId: values.category,
    categoryDetails: categoryDetails,
    subcategory: values.subcategory,
    state: values.state,
    stateDetails: stateDetails,
    address: values.address,
    mapCoordinates: values.mapCoordinates.center || values.mapCoordinates,
    author: user.toJson(),
    authorUid: user.id,
    createdAt: new Date(),
  };
};

const updateCategoryStateQuantity = async (stateId, categoryId, operation) => {
  const categoryDetails = await getSingleCategory(categoryId);

  const stateQuantity =
    (categoryDetails.quantity_per_state &&
      categoryDetails.quantity_per_state[stateId]) ||
    0;

  let newQuantity = 0;
  if (operation === "increase") {
    newQuantity = stateQuantity + 1;
  }

  if (operation === "decrease") {
    newQuantity = stateQuantity - 1;
  }

  const quantityPerState = {
    ...categoryDetails.quantity_per_state,
    [stateId]: newQuantity,
  };

  return await updateDiscountClubCategoryService(categoryDetails, {
    quantity_per_state: quantityPerState,
  });
};

export const createDiscountsListenerService = (
  { filters: { categoryId, stateId, disabled } },
  callback
) => {
  const user = getUserFromStore();
  let colPath = firestore.collection(DISCOUNTS_ENDPOINT_URL);
  if (!userCan({ perform: "discounts_club:see_all" })) {
    stateId = user._state._id;
  }
  if (stateId !== null) colPath = colPath.where("state", "==", stateId);
  if (categoryId !== null) colPath = colPath.where("categoryId", "==", categoryId);
  if (disabled !== null) colPath = colPath.where("disabled", "==", disabled);

  colPath.orderBy("createdAt", "asc");

  colPath.onSnapshot((snapshot) => {
    const data = [];
    snapshot.docs.forEach((item) => data.push({ id: item.id, ...item.data() }));

    callback(data);
  });
};
