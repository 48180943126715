import rules from "./rbac-rules";
import { getUserFromStore } from "../helpers/store";

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = ({ role, perform, data, yes, no }) => {
  const user = getUserFromStore();
  const userRole = role || user._role || user.role;
  return check(rules, userRole, perform, data) ? yes() : no();
};

export const userCan = ({ role, perform, data }) => {
  const user = getUserFromStore();
  const userRole = role || user._role || user.role;

  return check(rules, userRole, perform, data);
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
